// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

window.Rails = require("@rails/ujs")
// require("@hotwired/turbo-rails")
// require("@rails/activestorage").start()
// require("channels")
//require("trix")
//require("@rails/actiontext")
// require("local-time").start()

// import "../stylesheets/application.scss";
// import "@fortawesome/fontawesome-free/css/all"

// import * as bootstrap from "bootstrap"
// import './channels/**/*_channel.js'

// Start Rails UJS
Rails.start()

// import {Turbo} from "@hotwired/turbo-rails"
// import "@hotwired/turbo-rails"
// Turbo.session.drive = false

// Stimulus
// import "../controllers"

// import {Turbo} from "@hotwired/turbo-rails"
// import "@hotwired/turbo-rails"
// import mrujs from "mrujs";
// import * as Turbo from "@hotwired/turbo";
// Turbo must be set before starting mrujs for proper compatibility with querySelectors.
// window.Turbo = Turbo;

// Turbo.session.drive = false

// import { JsErb } from "mrujs/plugins"

// mrujs.start({
//   plugins: [
//     JsErb()
//   ]
// })

// Stimulus
// import "../controllers"

// Bootstrap
// import 'bootstrap'

// document.addEventListener("turbo:load", () => {
//   var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
//   var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
//     return new bootstrap.Tooltip(tooltipTriggerEl)
//   })

//   var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
//   var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
//     return new bootstrap.Popover(popoverTriggerEl)
//   })
// })
